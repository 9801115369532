import { graphql } from 'gatsby'
import React from 'react'
import { ArchiveContent } from 'starterComponents'
import Seo from '../../components/seo'

const PostArchive = (props) => {
  const {
    data: {
      page: { seo },
      posts: { nodes: posts },
    },
    pageContext: { nextPagePath, previousPagePath },
  } = props

  return (
    <>
      <Seo {...seo} />
      <ArchiveContent
        {...props}
        page="blog"
        // name="test" // this is the category / tag name
        posts={posts}
        nextPagePath={nextPagePath}
        previousPagePath={previousPagePath}
      />
    </>
  )
}

export default PostArchive

export const pageQuery = graphql`
  query WordPressPostArchive($id: String!, $offset: Int!, $postsPerPage: Int!) {
    page: wpPage(id: { eq: $id }) {
      title
      content
      seo {
        title
        fullHead
      }
    }

    posts: allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        ...PostTemplateFragment_starter
      }
    }
  }
`
